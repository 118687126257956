import React, { Component } from "react";

export default class PoliticaPrivacidade extends Component {
	render() {
		return (
			<div className="container">
				<div className="d-flex justify-content-center">
					<div>
						<div style={{ textAlign: 'center', marginTop: 44, marginBlock: 16 }}>
							<h1>Políticas de privacidade</h1>
						</div>
						<div style={{ height: '100vh' }}>
							<embed
								type="application/pdf"
								src="/politica_de_privacidade_-_Cashbarber.pdf"
								id="pdfDocument"
								width="100%"
								height="100%" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}