/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import ForgotPassword from "../pages/password/ForgotPassword";
import ResetPassword from "../pages/password/ResetPassword";
import TermoDeUso from "../pages/termos-uso/TermosUso";
import PoliticaDePrivacidade from "../pages/politica-privacidade/PoliticaPrivacidade";

export const Routes = withRouter(({ history }) => {
	const lastLocation = useLastLocation();
	routerHelpers.saveLastLocation(lastLocation);
	const { isAuthorized, menuConfig, userLastLocation } = useSelector(
		({ auth, urls, builder: { menuConfig } }) => ({
			menuConfig,
			isAuthorized: auth.user != null,
			userLastLocation: routerHelpers.getLastLocation()
		}),
		shallowEqual
	);

	return (
		/* Create `LayoutContext` from current `history` and `menuConfig`. */
		<LayoutContextProvider history={history} menuConfig={menuConfig}>
			<Switch>
				{!isAuthorized && useLocation().pathname === '/password/forgot' ?
					<Route path="/password/forgot" component={ForgotPassword} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/password/reset' ?
					<Route path="/password/reset" component={ResetPassword} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/termos-de-uso' ?
					<Route path="/termos-de-uso" component={TermoDeUso} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/politica-de-privacidade' ?
					<Route path="/politica-de-privacidade" component={PoliticaDePrivacidade} />
					: null
				}

				{!isAuthorized ? (
					/* Render auth page when user at `/auth` and not authorized. */
					<AuthPage />
				) : (
					/* Otherwise redirect to root page (`/`) */
					<Redirect from="/auth" to={userLastLocation} />
				)}

				<Route path="/error" component={ErrorsPage} />
				<Route path="/logout" component={LogoutPage} />

				{!isAuthorized ? (
					/* Redirect to `/auth` when user is not authorized */
					<Redirect to="/auth/login" />
				) : (
					<Layout>
						<HomePage userLastLocation={userLastLocation} />
					</Layout>
				)}
			</Switch>
		</LayoutContextProvider>
	);
});
